<template>
    <div class="relative h-safe-screen w-safe-screen bg-zinc-100 dark:bg-slate-800"
        :class="{
            'is-pwa': $isPwa
        }">

        <slot />



        <!-- toggle for darkmode -->
        <aside class="fixed top-safe right-safe transform translate-y-4 -translate-x-4 md:-translate-x-8 z-50">
            <a href="#"
                @click.prevent="toggleDarkMode"
                class=" rounded-full w-8 h-8 flex items-center justify-center
                    bg-white dark:bg-slate-700
                    text-zinc-900 dark:text-slate-100
                    border border-zinc-300/50 dark:border-slate-700/75"
                >
                <span v-if="$root.darkMode">
                    <i class="fa-regular fa-moon"></i>
                </span>
                <span v-else>
                    <i class="fa-regular fa-sun-bright"></i>
                </span>
            </a>
        </aside>
    </div>
</template>

<script>
    export default {
        methods: {
            toggleDarkMode() {
                if (this.$root.darkMode === true) {
                    // verwijderen
                    document.documentElement.classList.remove('dark');
                    this.$root.darkMode = false;
                    localStorage.setItem('theme', 'light');

                } else {
                    document.documentElement.classList.add('dark');
                    this.$root.darkMode = true;
                    localStorage.setItem('theme', 'dark');

                }
            },
        },
    }
</script>
