require('./bootstrap');


import { createApp, h } from 'vue';
import { createInertiaApp, Link } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import Layout from '@/Shared/BaseLayout.vue';
import { createPinia } from 'pinia';
import { useStore } from '@/stores/dashboard';

InertiaProgress.init();



createInertiaApp({
    resolve: async (name) => {
        let page = (await import(`./Pages/${name}`)).default;

        if (page.layout === undefined && !name.startsWith('Public/')) {
            page.layout = Layout;
        }
        return page;
    },


    setup({ el, App, props, plugin }) {

        const app = createApp({
            render: () => h(App, props),
            data() {
                return {
                    darkMode: false
                };
            },
            created () {
                if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
                    this.darkMode = true;
                } else {
                    this.darkMode = false;
                };


            },

        });
        app.mixin({
            computed: {
                $isPwa() {
                    return ["fullscreen", "standalone", "minimal-ui"].some(
                        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
                    );
                }
            },
        });

        // app.config.globalProperties.$emitter = emitter;

        app.use(plugin)
        .use(createPinia())
        .component('Link', Link)
        .mount(el);

    },
});