export async function apiCall(
    endpoint,
    post = null
) {

    let options = {
        headers: {
            'Accept' : 'application/json',
            'Content-Type': 'application/json',
            'X-Signature': Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
        }
    };
    if (post) {
        options.method = 'POST';
        options.cache = 'no-cache';
        options.body = JSON.stringify(post);
    }


    let raw = await fetch(endpoint, options);

    if (raw.status === 200) {
        return await raw.json();
    }

    return {
        status: raw.status,
        message: raw.statusText
    };

}