// window.axios = require('axios');

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/** 
 * Service worker registeration
 */

import {Workbox} from 'workbox-window';


console.log(process.env.NODE_ENV);

if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
    const wb = new Workbox('/service-worker.js');
    wb.register();
}


 







/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
