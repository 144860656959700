import { ulid } from 'ulid';
import { defineStore } from 'pinia';
import { apiCall } from '@/ApiCall.js';
import { detect } from 'detect-browser';

export const useDashboardStore = defineStore('dashboard', {
    state: () => {
        return {
            devui: null,
            settings: {
                track: true,
                trackZoom: 15
            },
            geometry: {
                currentPosition: null,
                pois: null
            },
            progress: {},
            allProgress: {}
        }
    },
    actions: {
        updateCurrentLocation(nieuw) {
            console.log(`🍍🎯 Locatie update ontvangen: ${nieuw[0]} x ${nieuw[1]}`);
            this.geometry.currentPosition = nieuw;

            // berekenen van nieuwe progressie adhv nieuwe locatie

        },


        async createAndCheckDevui(ritId) {

            let devuiInLocalStorage = await window.localStorage.getItem('tt_devui');

            if (!devuiInLocalStorage) {
                this.devui = ulid();
                await window.localStorage.setItem('tt_devui', this.devui);
                console.log('🍍📟 sending new devui async to TochtenAPI');
                const browser = detect();
                apiCall(`/api/devui-ping/${ritId}/${this.devui}`, {
                    resolution: `${screen.width}x${screen.height}`,
                    browser: browser
                });
            } else {
                this.devui = devuiInLocalStorage;
            };

            console.log(`🍍🔦 ${this.devui}`);

        },


        /*
        Progress */
        async progressReadFromLocalStorage() {
            let pr = await window.localStorage.getItem('tt_progress');
            if (pr === null) {
                // progress bestaat nog uberhaupt niet, dus aanmaken
                pr = {};
            } else {
                try {
                    pr = JSON.parse(pr);
                } catch (e) {
                    pr = {};
                }
            }

            // opslaan in LS
            window.localStorage.setItem('tt_progress', JSON.stringify(pr));

            this.allProgress = pr;

        },
        async progressPopulateCleanObject(ritId) {
            let obj = {
            };

            // alle POIs gebruiken als key
            this.geometry.pois.features.forEach((el) => {
                obj[el.properties.uuid] = {
                    status: null,
                }
            })

            await this.addRitProgressToAll(ritId, obj);

        },
        progressUseStored(ritId) {
            this.progress = this.allProgress[ritId];
        },
        async addRitProgressToAll(ritId, obj) {
            this.allProgress[ritId] = obj;
            await window.localStorage.setItem('tt_progress', JSON.stringify(this.allProgress));
        },


        addPois(pois) {
            this.geometry.pois = pois;
        },


        stopTracking() {  this.settings.track = false; },
        toggleTracking() {
            if (this.settings.track === false) {
                this.settings.track = true;
            } else {
                this.settings.track = false;
            }
        }
    }
})
